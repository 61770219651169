import { Container, Col, Row, ListGroup, ListGroupItem } from "react-bootstrap";
import Link from "next/link";
//import BreakingNews from "../marquee/breakingnews";
//import CategoryNav from "./category-nav";
import { baseUrl } from "api/constants";
import { debounce } from "debounce";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useSession, signOut } from "next-auth/react";
import useMobileDetect from "hooks/useMobileDetect";
import Sticky from "react-sticky-el";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getProfileById } from "redux/actions/userActions";
// import { getCookie, setCookie } from "utils/cookieHelper";
import { Modal } from "react-bootstrap";
// import ClevertapReact from "utils/clevertapHelper";
import { FormattedMessage } from "react-intl";
// import BreakingNews from "components/shared/marquee/breakingnews";
import CategoryNav from "components/shared/header/category-nav";
import { getSearchDropdown } from "redux/actions/searchActions";
// import { isHindiText } from "api/commonConstants";
// import { baseUrl } from "api/constants";
import { getLanguage } from "localization/i8intl";
import { isHindiText } from "api/commonConstants";

import { handleLogout as reduxLogout } from "redux/actions/authActions";
import {
  // createEditProfilePayload,
  // createLogoutPayload,
  // createOpenSettingPayload,
  MyLink,
} from "api/cleverTap";
// import Image from "next/image";
import { useWindowSize } from "api/commonConstants";
import { getUserIdFromCookies } from "constants/loginConstants";
// import SearchBox from "./searchComponent";
import dynamic from "next/dynamic";
import { setSelectedTab } from "redux/actions/categoryActions";
const SearchBox = dynamic(() => import("./searchComponent"));

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  span:first-child {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .option-dropdown {
    display: flex;
    align-items: center;
  }
  .dropdownList {
    position: absolute;
    background-color: #fff;
    top: 26px;
    right: 0px;
    z-index: 4;
    padding: 8px;
    box-shadow: 0px 0px 8px 0px #0000003d;
    i {
      margin-right: 5px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: 700;
      cursor: pointer;
    }
  }
`;

const Header = () => {




  
  const dispatch = useDispatch();

  const onTabClick = (value) => {
   
}




  const { userData } = useSelector(({ UserReducer }) => UserReducer);
  const { selectedTab } = useSelector(({ CategoryReducer }) => CategoryReducer);
  const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  const dropdownList = useSelector(
    ({ SearchReducer }) => SearchReducer.dropdown
  );
  const size = useWindowSize();
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(
    router?.pathname.includes("/video")
      ? "video"
      : router?.pathname.includes("/text")
      ? "text"
      : router?.pathname.includes("/audio")
      ? "audio"
      : "all"
  );
  const [activeCategory , setActiveCategory] = useState(null) 
  const [showSearch, setshowSearch] = useState(false);

  const [dropdownListText, setDropdownListText] = useState([]);
  const [dropdownListVideo, setDropdownListVideo] = useState([]);
  const [dropdownListAudio, setDropdownListAudio] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSuggestDropdown, setShowSuggestDropdown] = useState(false);
  const { data: session, status: _ } = useSession();
  const [searchText, setSearchText] = useState("");
  const [profileImage, setProfileImage] = useState();
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);
  const [openDownloadAppMobileModal, setOpenDownloadAppMobileModal] =
    useState(false);

  // const { contentType } = router.query;
  // const formQuery = useCallback(
  //   (type) => {
  //     const { contentType, ...restQuery } = router.query;
  //     return { ...restQuery, contentType: contentType ? type : type };
  //   },
  //   [router.query]
  // );

  const [language, setLanguage] = useState("");


  useEffect(() => {
    if(activeCategory == 'text' || activeCategory == 'video'){
      dispatch(setSelectedTab(activeCategory))
    }
    else{
      dispatch(setSelectedTab(null))
    }
  },[activeCategory])
  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  useEffect(() => {
    console.log("Search Text changed to", searchText);
    if (searchText) {
      fetchSearchSuggestions(searchText);
    }
  }, [searchText]);
  console.log(router.pathname, "router.path");

  useEffect(
    () =>
      setShowHeader(
        router.pathname !== "/login" &&
          router.pathname !== "/preferences" &&
          !router.pathname.includes("lallantop-team") &&
          router.pathname !== "/cricket-page" &&
          !router.pathname.includes("/app-view") &&
          !router.pathname.includes("/amp/") &&
          !router.pathname.includes("/short-video/")
      ),
    [router.pathname]
  );
  useEffect(() => {
    console.log("showHeader", showHeader);
  }, [showHeader]);
  useEffect(() => {
    // const lallantop = JSON.parse(getCookie("lallantop") || "{}");
    // fetch("/api/getToken", {
    //   method: "get",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => res.json())
    //   .then(({ userId }) => {
    //     if (userId) {
    //       dispatch(getProfileById({ _id: userId }));
    //     }
    //   });
    const userId = getUserIdFromCookies(document.cookie);
    if (userId) {
      dispatch(getProfileById({ _id: userId }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      (router.pathname.includes("search") && size.width <= 499) ||
      router.pathname === "/login" ||
      router.pathname === "/cricket-page" ||
      router.pathname.includes("/app-view") ||
      router.pathname.includes("/amp/") ||
      router.pathname.includes("/short-video/") ||
      router.pathname === "/adda/schedule"
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  });


  useEffect(() => {
    // Set the active tab and category based on the pathname
    if (router?.pathname.includes("/video")) {
      setActiveTab("video");
      setActiveCategory("video");
    } else if (router?.pathname.includes("/text")) {
      setActiveTab("text");
      setActiveCategory("text");
    } else if (router?.pathname.includes("/audio")) {
      setActiveTab("audio");
      // setActiveCategory("audio");
    } else if (router?.pathname.includes("/show")) {
      setActiveTab("all");
      // setActiveCategory("show");
    } else {

      //checking if query params has text or video
      const params = new URLSearchParams(window.location.search);
      const type = params.get('type')
      if(type == 'text' || type == 'video'){
        setActiveCategory(type);
      }
      setActiveTab("all");
      // setActiveCategory("all");
    }
  }, [router?.pathname]);



  const isShow = useMobileDetect().isDesktop();

  useEffect(() => {
    // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //   })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  }, []);
  const fetchSearchSuggestions = debounce(async (text) => {
    fetch(
      `${baseUrl}/search/dropdownWithSlug?page=1&limit=10${
        isHindiText(text) ? `&language=Hindi` : ""
      }&search=${encodeURIComponent(text)}`
    )
      .then((res) => res.json())
      .then((d) => {
        console.log("dis", d?.result?.data);
        setDropdownListText(d?.result?.data?.textSuggestion);
        setDropdownListVideo(d?.result?.data?.videoSuggestion);
        setDropdownListAudio(d?.result?.data?.audioSuggestion);
      });
  }, 300);

  const handleLogout = () => {
    fetch("/api/clearToken", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ encryptedToken: "" }),
    }).then((res) => {
      if (res.status === 200) {
        localStorage.removeItem("lallantopSession2");
        localStorage.removeItem("imageData");
        reduxLogout();
        signOut({ callbackUrl: `${window.location.origin}` });
        //setCookie("lallantop", "", 0, "1970-01-01T00:00:00.000Z");
      }
    });
  };

  const dropdown = useRef(null);
  const searchRef = useRef(null);
  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!showDropdown) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setShowDropdown("");
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [showDropdown]);

  useEffect(() => {
    if (isShow) {
      setshowSearch(true);
    }
  }, [isShow]);

  useEffect(() => {
    if (searchText.length > 0) {
      dispatch(getSearchDropdown(searchText));
      // fetch(`${baseUrl}/search/dropdown?page=1&limit=25${isHindiText(searchText) ? `&language=Hindi` : ""}&search=${searchText}`)
      //   .then((res) => res.json())
      //   .then((d) => {
      //     setDropdownList(d?.result);
      //   });
      if (!showSuggestDropdown) {
        setShowSuggestDropdown(true);
      }
    } else {
      if (showSuggestDropdown) {
        setShowSuggestDropdown(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, setSearchText]);

  function callGtag() {
    // window.googletag = window.googletag || { cmd: [] };
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   dataLayer.push(arguments);
    // }
    // gtag("js", new Date());
    // gtag("config", "G-RXRPKR4310");
    // (function (w, d, s, l, i) {
    //   w[l] = w[l] || [];
    //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //   var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s),
    //     dl = l != "dataLayer" ? "&l=" + l : "";
    //   j.async = true;
    //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //   f.parentNode.insertBefore(j, f);
    // })(window, document, "script", "dataLayer", "GTM-P9LRV6T");
    // const gtag1 = document.createElement("script");
    // gtag1.setAttribute(
    //   "src",
    //   "https://www.googletagmanager.com/gtag/js?id=UA-203175993-6"
    // );
    // gtag1.setAttribute("async", "true");
    // document.head.appendChild(gtag1);
    // const gtag2 = document.createElement("script");
    // gtag2.setAttribute(
    //   "src",
    //   "https://www.googletagmanager.com/gtag/js?id=G-RXRPKR4310"
    // );
    // gtag2.setAttribute("async", "true");
    // document.head.appendChild(gtag2);
  }

  // useEffect(() => ClevertapReact.initialize("675-R7R-9R6Z"), []);
  // useEffect(() => {
  //   const random = Date.now().toString();
  //   const sessionUser = sessionStatus === "authenticated" ? session.user : null;
  //   const appUser = Object.keys(userData) > 0 ? userData : null;
  //   // if (!clevertap) return;
  //   ClevertapReact.profile({
  //     Site: {
  //       Name: appUser ? appUser.fullname : "Guest_" + random,
  //       Identity: appUser ? appUser._id : random, // String or number
  //       Email: sessionUser
  //         ? sessionUser?.email
  //         : `guest_user_${random}@lallantop.com`,
  //       Phone: appUser ? appUser.phoneNumber : "",
  //       DOB: new Date(0),
  //       "MSG-push": true,
  //     },
  //   });
  // }, [session?.user, sessionStatus, userData]);

  // useEffect(() => {
  //   // console.log(window.clevertap);
  //   window.clevertap.notificationCallback = function (msg) {
  //     //raise the notification viewed and clicked events in the callback
  //     window.clevertap.raiseNotificationViewed();
  //     // console.log(JSON.stringify(msg)); //your custom rendering implementation here
  //     var $button = jQuery("<button></button>"); //element on whose click you want to raise the notification clicked event
  //     $button.click(function () {
  //       window.clevertap.raiseNotificationClicked();
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   // console.log(window.clevertap);
  //   window.clevertap.notificationCallback = function (msg) {
  //     //raise the notification viewed and clicked events in the callback
  //     window.clevertap.raiseNotificationViewed();
  //     // console.log(JSON.stringify(msg)); //your custom rendering implementation here
  //     var $button = jQuery("<button></button>"); //element on whose click you want to raise the notification clicked event
  //     $button.click(function () {
  //       window.clevertap.raiseNotificationClicked();
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   ClevertapReact.notification({
  //     titleText: "Would you like to receive Push Notifications?",
  //     bodyText: "We promise to only send you relevant contents",
  //     okButtonText: "Sign me up!",
  //     rejectButtonText: "No thanks",
  //     okButtonColor: "#F28046",
  //     askAgainTimeInSeconds: 5,
  //     serviceWorkerPath: "../clevertap_sw.js",
  //   });
  // }, []);

  const loginRedirectUrl = router.pathname
    .replace("[categoryName]", router.query?.categoryName)
    .replace("[slug]", router.query?.slug);

  // useEffect(() => {
  //   setActiveTab(contentType || "all");
  // }, [contentType, activeTab]);

  // useEffect(() => {
  //   setActiveTab(contentType || "all");
  // }, [contentType, activeTab]);

  useEffect(() => {
    const image =
      userData?.profileImage || userLocalDetail?.image
        ? userData?.profileImage || userLocalDetail?.image
        : session?.user?.image
        ? session?.user?.image
        : "/assets/images/user-circle.svg";
    if (image !== profileImage) {
      setProfileImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user?.image, userData?.profileImage, userLocalDetail?.image]);

  return (
    <>
      {showHeader &&
        (!router.pathname.includes("/quiz") ||
          router.pathname.includes("/amp/") ||
          (router.pathname.includes("/quiz") && size.width > 650)) && (
          <header className="head-fixed site-header">
            <Container fluid>
              <Row
                className={
                  router.pathname.includes("/post/")
                    ? "top-strip "
                    : "top-strip"
                }
              >
                <Col className="d-flex col-12">
                  <div className="head-section">
                    <div className="logo">
                      <a href={`${process.env.NEXT_PUBLIC_BASE_URL}`} passHref>
                        <a className="lallan-logo" title="लल्लनटॉप">
                          <img
                            src="https://static.thelallantop.com/images/post/1695637095493_lallantop-logo_(1).webp?width=120"
                            width="70"
                            height="60"
                            loading="lazy"
                            alt="Lallantop Hindi News"
                          />
                        </a>
                      </a>{" "}
                      {router.pathname === "/" && (
                        <h1 className="h1Hide">Lallantop Hindi News</h1>
                      )}
                      {/* <a href="/" passHref> */}
                      <a href={`${process.env.NEXT_PUBLIC_BASE_URL}`} className="m-logo" title="लल्लनटॉप">
                        <img
                          src="https://static.thelallantop.com/images/post/1695637095493_lallantop-logo_(1).webp?width=35&height=35"
                          width="35"
                          height="35"
                          loading="lazy"
                          alt="The Lallantop"
                        />
                      </a>
                      {/* </a> */}
                    </div>
                  </div>
                  <div className="mr-head">
                    <Row className="width-100">
                      <Col sm={12} className="mb-1 d-none d-xl-block">
                        <div className="flashnews-login">
                          {/* <div className="breakingNews">
                          <h5 className="breaking-newstxt">
                            <a href="/" passHref>
                              <a>
                                <span>
                                  <FormattedMessage
                                    id="trending_news"
                                    defaultMessage="ट्रेंडिंग न्यूज़"
                                  />
                                </span>
                              </a>
                            </a>
                          </h5>
                          <div className="flashnews">
                            <BreakingNews />
                          </div>
                        </div> */}
                          <div className="m-visible">
                            {userLocalDetail?.id || session ? (
                              <DropdownDiv
                                className="more-col"
                                onClick={() => {
                                  if (!showDropdown) {
                                    // ClevertapReact.event(
                                    //   "profile_settings",
                                    //   createOpenSettingPayload(
                                    //     "profile_settings",
                                    //     userData
                                    //   )
                                    // );
                                  }
                                  setShowDropdown((b) => !b);
                                }}
                                ref={dropdown}
                              >
                                <span>
                                  {userData?.fullname ||
                                    userLocalDetail?.name ||
                                    session?.user?.name ||
                                    ""}
                                </span>
                                <img
                                  src={
                                    // userData?.profileImage ||
                                    // userLocalDetail?.image ||
                                    // session?.user?.image ||
                                    // "/assets/images/user-circle.svg"
                                    profileImage
                                  }
                                  referrerPolicy="no-referrer"
                                  width="25"
                                  height="25"
                                  alt="user"
                                  style={{ borderRadius: 25 }}
                                />
                                <div className="option-dropdown">
                                  {showDropdown && (
                                    <div className="dropdown-list">
                                      <ul>
                                        <li>
                                          <a href={`${process.env.NEXT_PUBLIC_BASE_URL}/loading/profile`} passHref>
                                            <MyLink
                                              onCustomClick={() => {
                                                // ClevertapReact.event(
                                                //   "profile_editprofile",
                                                //   createEditProfilePayload(
                                                //     "profile_editprofile",
                                                //     userData
                                                //   )
                                                // );
                                              }}
                                            >
                                              <span>
                                                <FormattedMessage
                                                  id="edit_profile"
                                                  defaultMessage="एडिट प्रोफाइल"
                                                />
                                              </span>
                                            </MyLink>
                                          </a>
                                        </li>
                                        <li
                                          onClick={() => {
                                            // ClevertapReact.event(
                                            //   "settings_logout",
                                            //   createLogoutPayload(
                                            //     "settings_logout",
                                            //     userData
                                            //   )
                                            // );
                                            handleLogout();
                                          }}
                                        >
                                          <a>
                                            <span>
                                              <FormattedMessage
                                                id="logout"
                                                defaultMessage="लॉग आउट"
                                              />
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </DropdownDiv>
                            ) : (
                              <Link
                                href={{
                                  pathname: `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/signin`,
                                  query: {
                                    callbackUrl:
                                      router.query?.categoryName &&
                                      router.query?.categoryName
                                        ? loginRedirectUrl
                                        : router.pathname,
                                  },
                                }}
                                passHref
                              >
                                <a className="login">
                                  <span>
                                    <FormattedMessage
                                      id="signin"
                                      defaultMessage="साइन इन करें"
                                    />
                                  </span>
                                  <i className="icon-user"></i>
                                </a>
                              </Link>
                            )}
                          </div>
                        </div>
                      </Col>
                      <div className="cate-search">
                        <div className="header-category">
                          <ListGroup
                            as="ul"
                            className="category-menu"
                            horizontal
                          >
                            <ListGroupItem as="li" active={!selectedTab && activeTab === "all"}>
                              {/* <a
                                href={{
                                  pathname: "/",
                                  //query: formQuery("all"),
                                  //query: "/"
                                }}
                                passHref
                              > */}
                              <a
                                href={`${process.env.NEXT_PUBLIC_BASE_URL}`}
                                onClick={() => {
                                  if (typeof window.ga !== "undefined") {
                                    ga("create", "G-RXRPKR4310", "auto");
                                    ga("send", "event", {
                                      eventCategory: "top_navigation",
                                      eventAction: "Click",
                                      eventLabel: "top_nav_home",
                                      eventValue:
                                        "https://www.thelallantop.com/",
                                    });
                                  }

                                  setActiveTab("all");
                                }}
                              >
                                <i className="icon-home"></i>
                                <span>
                                  <FormattedMessage
                                    id="all"
                                    defaultMessage="होम"
                                  />
                                </span>
                              </a>
                              {/* </a> */}
                            </ListGroupItem>
                            <ListGroupItem
                              as="li"
                              active={selectedTab == 'text' || activeTab === "text"}
                            >
                              {/* <a
                                href={{
                                  pathname: `/text`,
                                  //query: "/video"
                                  //query: formQuery("text"),
                                }}
                                passHref
                              > */}
                              <a
                                href={`${process.env.NEXT_PUBLIC_BASE_URL}/text`}
                                onClick={() => {
                                  if (typeof window.ga !== "undefined") {
                                    ga("create", "G-RXRPKR4310", "auto");
                                    ga("send", "event", {
                                      eventCategory: "top_navigation",
                                      eventAction: "Click",
                                      eventLabel: "top_nav_read",
                                      eventValue:
                                        "https://www.thelallantop.com/text",
                                    });
                                  }

                                  setActiveTab("text");
                                  setActiveCategory("text")
                                }}
                              >
                                <i className="icon-read"></i>
                                <span>
                                  <FormattedMessage
                                    id="read"
                                    defaultMessage="पढ़िए"
                                  />
                                </span>
                              </a>
                              {/* </a> */}
                            </ListGroupItem>
                            <ListGroupItem
                              as="li"
                              active={selectedTab == 'video' || activeTab === "video"}
                            >
                              {/* <a
                                href={{
                                  pathname: "/video",
                                  //query: formQuery("video"),
                                  //query: "/video"
                                }}
                                passHref
                              > */}
                              <a
                                href={`${process.env.NEXT_PUBLIC_BASE_URL}/video`}
                                onClick={() => {
                                  if (typeof window.ga !== "undefined") {
                                    ga("create", "G-RXRPKR4310", "auto");
                                    ga("send", "event", {
                                      eventCategory: "top_navigation",
                                      eventAction: "Click",
                                      eventLabel: "top_nav_video",
                                      eventValue:
                                        "https://www.thelallantop.com/video",
                                    });
                                  }

                                  setActiveTab("video");
                                  setActiveCategory("video")
                                }}
                              >
                                <i className="icon-cat-video"></i>
                                <span>
                                  <FormattedMessage
                                    id="video"
                                    defaultMessage="देखिए"
                                  />
                                </span>
                              </a>
                              {/* </a> */}
                            </ListGroupItem>
                            <ListGroupItem
                              as="li"
                              active={!selectedTab && activeTab === "audio"}
                            >
                              {/* <a
                                href={{
                                  pathname: "/audio",
                                  //query: formQuery("video"),
                                  //query: "/video"
                                }}
                                passHref
                              > */}
                              <a
                                href={`${process.env.NEXT_PUBLIC_BASE_URL}/audio`}
                                onClick={() => {
                                  if (typeof window.ga !== "undefined") {
                                    ga("create", "G-RXRPKR4310", "auto");
                                    ga("send", "event", {
                                      eventCategory: "top_navigation",
                                      eventAction: "Click",
                                      eventLabel: "top_nav_audio",
                                      eventValue:
                                        "https://www.thelallantop.com/audio",
                                    });
                                  }

                                  setActiveTab("audio");
                                }}
                              >
                                <i className="icon-cat-listen"></i>
                                <span>
                                  <FormattedMessage
                                    id="audios"
                                    defaultMessage="सुनिए"
                                  />
                                </span>
                              </a>
                              {/* </a> */}
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                        <div className="header-serch m-visible">
                          {showSearch && (
                            <div
                              ref={searchRef}
                              className="search-box-container"
                            >
                              <SearchBox
                                searchText={searchText}
                                setSearchText={setSearchText}
                              />
                            </div>
                          )}
                          <div className="suggest-option" ref={searchRef}>
                            <div className="suggest-option-dropdown">
                              {showSuggestDropdown && (
                                <div className="dropdown-list">
                                  <ul>
                                    {dropdownListText &&
                                      dropdownListText
                                        .slice(0, 5)
                                        .map((item, index) => (
                                          <a
                                            href={item.permalink}
                                            key={index}
                                            passHref={true}
                                          >
                                            <li key={index}>
                                              Article :
                                              <a
                                                onClick={() =>
                                                  setSearchText("")
                                                }
                                              >
                                                <span>{item?.title}</span>
                                              </a>
                                            </li>
                                          </a>
                                        ))}
                                    {dropdownListVideo &&
                                      dropdownListVideo
                                        .slice(0, 5)
                                        .map((item, index) => (
                                          <li key={index}>
                                            Video :
                                            <a
                                              href={item.permalink}
                                              passHref={true}
                                            >
                                              <a
                                                onClick={() =>
                                                  setSearchText("")
                                                }
                                              >
                                                <span>{item?.title}</span>
                                              </a>
                                            </a>
                                          </li>
                                        ))}
                                    {dropdownListAudio &&
                                      dropdownListAudio
                                        .slice(0, 5)
                                        .map((item, index) => (
                                          <li key={index}>
                                            Audio :
                                            <a
                                              href={item.permalink}
                                              passHref={true}
                                            >
                                              <a
                                                onClick={() =>
                                                  setSearchText("")
                                                }
                                              >
                                                <span>{item?.title}</span>
                                              </a>
                                            </a>
                                          </li>
                                        ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="search-action">
                            {showSearch ? (
                              <button
                                onClick={() => {
                                  setshowSearch(false);
                                  setSearchText("");
                                }}
                                aria-label="close button"
                              >
                                <i className="icon-close"></i>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  if (size.width < 500) {
                                    router.push("/search");
                                  } else {
                                    setShowSearch(true);
                                  }
                                }}
                                aria-label="close button"
                              >
                                <i className="icon-search"></i>
                              </button>
                            )}
                          </div>

                          {/* <a href="/social-arena" passHref>
                          <a
                            className="arena-btn arena-img"
                            aria-label="social arena"
                          >
                            <img
                              src="/assets/images/social-arena.svg"
                              width="30"
                              height="30"
                              alt="Social Arena"
                            />
                            <span>
                              <FormattedMessage
                                id="social_news"
                                defaultMessage="सोशल फीड"
                              />
                            </span>
                          </a>
                        </a> */}

                          {/* <a
                          className="m-visible"
                          href="https://onelink.to/hsnwrm"
                          passHref
                        >
                          <a
                            className="arena-btn download-app-mobile"
                            target="_blank"
                          >
                            <img
                              src="/assets/images/download_app_mobile.svg"
                              width="30"
                              height="30"
                              alt="Download App"
                            />
                          </a>
                        </a> */}

                          <div className="m-visible">
                            <div
                              role={"button"}
                              onClick={() =>
                                setOpenDownloadAppMobileModal(true)
                              }
                              className="arena-btn download-app-mobile"
                            >
                              <img
                                src={
                                  "https://static.thelallantop.com/images/post/1707897605995_download_app_mobile_(1)[1].webp?width=30&height=30"
                                }
                                width="30"
                                height="30"
                                alt="Download App"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="download_img header-serch d-visible">
                          <div
                            role={"button"}
                            onClick={() => setOpenDownloadAppModal(true)}
                          >
                            {language === "en" ? (
                              <img
                                src={
                                  "https://static.thelallantop.com/images/post/1707895548615_group_1894.webp?width=300&height=75"
                                }
                                width="300"
                                height="75"
                                alt="The Lallantop"
                              />
                            ) : (
                              <img
                                // loading="lazy"
                                src="https://static.thelallantop.com/images/post/1690877310438_download_app_hindi.webp?width=300"
                                width={375}
                                height={75}
                                alt="The Lallantop"
                              ></img>
                              // <img
                              //   src="/assets/images/download_app_hindi.svg"
                              //   width="300"
                              //   height="75"
                              //   alt="The Lallantop"
                              // />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <Col sm={12}>
                      <Row>
                        <Col sm={9} xl={7} xs={10} className="mb-sm-0">
                          <ListGroup
                            as="ul"
                            className="category-menu"
                            horizontal
                          >
                            <ListGroupItem as="li" active={activeTab === "all"}>
                              <a
                                href={{
                                  pathname: router.pathname,
                                  query: formQuery("all"),
                                }}
                                passHref
                              >
                                <a onClick={() => setActiveTab("all")}>
                                  <i className="icon-home"></i>
                                  <span>
                                    <FormattedMessage
                                      id="all"
                                      defaultMessage="होम"
                                    />
                                  </span>
                                </a>
                              </a>
                            </ListGroupItem>
                            <ListGroupItem
                              as="li"
                              active={activeTab === "text"}
                            >
                              <a
                                href={{
                                  pathname: router.pathname,
                                  query: formQuery("text"),
                                }}
                                passHref
                              >
                                <a onClick={() => setActiveTab("text")}>
                                  <i className="icon-read"></i>
                                  <span>
                                    <FormattedMessage
                                      id="read"
                                      defaultMessage="पढ़िए"
                                    />
                                  </span>
                                </a>
                              </a>
                            </ListGroupItem>
                            <ListGroupItem
                              as="li"
                              active={activeTab === "video"}
                            >
                              <a
                                href={{
                                  pathname: router.pathname,
                                  query: formQuery("video"),
                                }}
                                passHref
                              >
                                <a onClick={() => setActiveTab("video")}>
                                  <i className="icon-cat-video"></i>
                                  <span>
                                    <FormattedMessage
                                      id="video"
                                      defaultMessage="वीडियो"
                                    />
                                  </span>
                                </a>
                              </a>
                            </ListGroupItem>
                            
                          </ListGroup>
                        </Col>
                        <Col sm={3} xl={5} xs={2}>
                          <div className="search-action">
                            <button onClick={() => setshowSearch((e) => !e)}>
                              {showSearch ? (
                                <i className="icon-close"></i>
                              ) : (
                                <i className="icon-search"></i>
                              )}
                            </button>
                          </div>
                          {showSearch && <SearchBox />}
                        </Col>
                      </Row>
                    </Col> */}
                    </Row>
                  </div>
                  {/* <div className="download_img d-visible">
                  <a href="/" passHref>
                    <a>
                      <img
                        src="/assets/images/download_app.svg"
                        width="300"
                        height="75"
                        alt="The Lallantop"
                      />
                    </a>
                  </a>
                </div> */}
                </Col>
              </Row>
              {/* {router.pathname.includes("/post/") && (
                <Row className="bottom-strip">
                  <CategoryNav
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Row>
              )} */}

              <Row>
                <Sticky className="head-fixed">
                  <CategoryNav
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Sticky>
              </Row>
            </Container>
          </header>
        )}

      <Modal
        show={openDownloadAppModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenDownloadAppModal(false)}
      >
        <div className="row p-2">
          <div className="col-6" style={{ fontSize: "18px" }}>
            <span>
              <FormattedMessage
                id="dowload_app"
                defaultMessage="ऐप डाउनलोड करें"
              />
            </span>
          </div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenDownloadAppModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center" style={{ marginBottom: "3rem" }}>
            <div className="qr-image-container-header mb-2">
              {/* <img
                src="/assets/images/app_share_lallantop.webp"
                alt="Google Play"
                className="qr-image-header"
              /> */}
              <img
                src="https://static.thelallantop.com/images/post/1738143249898_onelinkto_p5kpke_(1).webp"
                alt="Google Play"
                loading="lazy"
                className="qr-image-header"
                width="100px"
                height="100px"
                //   sizes="(max-width: 1500px) 100vw,
                //   (max-width: 768px) 70vw,
                //   (max-width: 500px) 40vw,
                //   33vw
                //  "
              />
            </div>

            <span>
              <FormattedMessage
                id="lallantop_mobile_app"
                defaultMessage="लल्लनटॉप मोबाइल डाउनलोड करने के लिए QR कोड स्कैन करें"
              />
            </span>
            {/* <span style={{ fontSize: "16px" }} className="text-dark mb-2">
              Scan QR Code To Download
            </span>
            <br />
            <span style={{ fontSize: "16px" }} className="text-dark mb-2">
              <strong>The Lallantop Mobile App</strong>
            </span> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openDownloadAppMobileModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenDownloadAppMobileModal(false)}
      >
        <div className="row p-2">
          {/* <div className="col-6" style={{ fontSize: "18px" }}>
            <span>
              <FormattedMessage
                id="dowload_app"
                defaultMessage="ऐप डाउनलोड करें"
              />
            </span>
          </div> */}
          <div className="text-end">
            <div
              role={"button"}
              onClick={() => setOpenDownloadAppMobileModal(false)}
            >
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center" style={{ marginBottom: "3rem" }}>
            <div className="qr-image-container-header mb-2">
              <img
                src="/assets/images/m-logo.svg"
                alt="Lallantop logo"
                loading="lazy"
                className="logo-download-app"
                width="70"
                height="70"
              />
            </div>

            <span className="mobile_app_download_text">
              <FormattedMessage
                id="lallantop_app_download_mobile"
                defaultMessage="ताज़ा ख़बरें, वीडियो, डेली शो और किस्सों का ठिकाना"
              />
            </span>

            <br />

            <div className="mobile_app_download_btn">
              <a
                href="https://onelink.to/hsnwrm"
                className="arena-btn download-app-mobile"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <FormattedMessage
                    id="lallantop_app_download_mobile_btn"
                    defaultMessage="अभी डाउनलोड करें"
                  />
                </span>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Header;
